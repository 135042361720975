<template>
  <div class='refunds_info'>
    <back />

    <div class="info" v-loading="loading">
      <div class="title">退换货信息</div>

      <el-form :inline="true" :model="form" class="form" label-width="150px">
        <el-form-item label="商品编号">
          <el-input v-model="form.productCode" readonly></el-input>
        </el-form-item>
        <el-form-item label="商品名称">
          <el-input v-model="form.productName" readonly></el-input>
        </el-form-item>
        <el-form-item label="商品分类">
          <el-input v-model="form.categoryName" readonly></el-input>
        </el-form-item>
        <el-form-item label="规格">
          <el-input v-model="form.specs" readonly></el-input>
        </el-form-item>
        <el-form-item label="数量">
          <el-input v-model="form.returnsCount" readonly></el-input>
        </el-form-item>
        <el-form-item label="单价">
          <el-input v-model="form.productPrice" readonly></el-input>
        </el-form-item>
        <el-form-item label="总价">
          <el-input v-model="form.totalAmount" readonly></el-input>
        </el-form-item>
        <el-form-item label="类型">
          <el-input v-model="form.type" readonly></el-input>
        </el-form-item>
        <el-form-item label="厂家">
          <el-input v-model="form.factoryName" readonly></el-input>
        </el-form-item>
        <el-form-item label="申请时间">
          <el-input v-model="form.createdTime" readonly></el-input>
        </el-form-item>
        <el-form-item label="商超名称">
          <el-input v-model="form.shopName" readonly></el-input>
        </el-form-item>
        <el-form-item label="商超地址">
          <!-- <el-tooltip :content="form.address" placement="top"> -->
          <el-input v-model="form.address" readonly></el-input>
          <!-- </el-tooltip> -->
        </el-form-item>
        <el-form-item label="商超联系人">
          <el-input v-model="form.contactMan" readonly></el-input>
        </el-form-item>
        <el-form-item label="商超电话">
          <el-input v-model="form.contactTel" readonly></el-input>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      loading: false,
      id: '',
      form: {},
    }
  },
  created () {
    this.id = this.$route.query.id
    this.getProductReturnsQuery()
  },
  methods: {
    // 用户列表
    getProductReturnsQuery () {
      this.loading = true
      this.$axios.get(this.$api.productReturnsQuery, {
        params: {
          id: this.id,
        }
      }).then((res) => {
        this.form = res.data.result
        this.form.type = res.data.result.returnsType == 1 ? '退货' : '换货'
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
  },
}
</script>

<style scoped lang='scss'>
.refunds_info {
  .info {
    padding: 30px;
    box-sizing: border-box;

    .title {
      color: #1A1A1A;
      font-size: 16px;
      font-weight: bold;
    }

    .form {
      margin-top: 30px;

      .el-form-item {
        margin-bottom: 30px;
      }

      .el-input {
        width: 350px;

        /deep/ .el-input__inner {
          border: 1px solid #E5E7E6;
          background-color: #fff;
          cursor: default;
        }
      }

      /deep/ .el-form-item__label {
        color: #1A1A1A;
        font-size: 16px;
        padding: 0 30px 0 0;
      }
    }
  }
}
</style>
